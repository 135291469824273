import { z } from "zod";

import { uuidSchema } from "./uuid";
import zodLaxNativeEnum from "../zodLaxNativeEnum";

export enum AssetType {
  Crypto = "Crypto",
  NFT = "NFT",
  Equity = "Equity",
  Fiat = "Fiat",
  PreciousMetal = "PreciousMetal",
  DeprecatedPreciousMetal = "Precious Metal",
  LiquidityPool = "LiquidityPool",
  DeprecatedLiquidityPool = "Liquidity Pool",
  Unknown = "Unknown",
}

export const assetBlockchainSchema = z.string();
export const assetContractAddressSchema = z.string();

export const assetNameSchema = z.string();

export const assetQuantityStringSchema = z.string();

export const assetCodeSchema = z.string();

export const assetSymbolSchema = z.string();

export const assetUuidSchema = z
  .string()
  .refine((assetUuid) => uuidSchema.safeParse(assetUuid).success);

export const nftIdSchema = z.string();

export const nftImageSchema = z.string();

export const nftContractNameSchema = z.string();

export const nftDataSchema = z.object({
  nft_id: nftIdSchema,
  nft_image: nftImageSchema,
  contract_name: nftContractNameSchema,
});

export type NFTData = z.infer<typeof nftDataSchema>;

export const assetSchema = z.object({
  uuid: assetUuidSchema,
  name: assetNameSchema,
  code: assetCodeSchema,
  symbol: assetSymbolSchema.optional(),
  type: zodLaxNativeEnum(AssetType),
  blockchain: assetBlockchainSchema.optional(),
  nft_data: nftDataSchema.optional(),
  contract_address: assetContractAddressSchema.optional(),
});

export type Asset = z.infer<typeof assetSchema>;

export const assetQuantitySchema = z.object({
  asset: assetSchema,
  quantity: assetQuantityStringSchema,
});

export type AssetQuantity = z.infer<typeof assetQuantitySchema>;
